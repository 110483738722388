<template>
    <div class="wrapper">
        <div class="main-section">
            <div class="main-container">
                <carousel
                    :adjustableHeight="true"
                    :per-page="1"
                    :navigationEnabled="true"
                    :autoplay="true"
                    :loop="true"
                    navigationNextLabel="→"
                    navigationPrevLabel="←"
                >
                    <slide v-for="image in images" :key="image.id" :style="{ height: 'fit-content' }">
                        <div class="slider">
                            <div class="slider-content">
                                <img :src="image.filename" />
                            </div>
                        </div>
                    </slide>

                    <template #addons>
                        <navigation />
                        <pagination />
                    </template>
                </carousel>
                <div class="bg-bottom"></div>
                <div class="main-content">
                    <h1 class="heading1">
                        <span>{{ $t('Au cœur de Villemomble') }}</span>
                        <br />
                        {{ $t('VOTRE NOUVELLE ADRESSE: HABITEZ À 7mn À PIED DE LA GARE RER E.') }}
                    </h1>
                </div>
                <a v-scroll-to="'#form-container'" href="#form-container" class="contact-btn">
                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="25.243" viewBox="0 0 24 25.243">
                        <g
                            id="Icon_feather-arrow-down"
                            data-name="Icon feather-arrow-down"
                            transform="translate(-6 30.621) rotate(-90)"
                        >
                            <g id="Group 75" data-name="Group 75">
                                <path
                                    id="Path_4087"
                                    data-name="Path 4087"
                                    d="M18,7.5v21"
                                    fill="none"
                                    stroke="#3e4d57"
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                    stroke-width="3"
                                />
                                <path
                                    id="Path_4088"
                                    data-name="Path 4088"
                                    d="M28.5,18,18,28.5,7.5,18"
                                    fill="none"
                                    stroke="#3e4d57"
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                    stroke-width="3"
                                />
                            </g>
                        </g>
                    </svg>
                    {{ $t('En savoir plus') }}
                </a>
            </div>
        </div>
    </div>
</template>

<script>
import { Carousel, Slide, Pagination, Navigation } from 'vue-carousel';
import imgMain1 from '../assets/images/banner1.png';
import imgMain2 from '../assets/images/banner2.jpg';
// import imgMain3 from '../assets/images/banner.png';

export default {
    name: 'MainSection',
    components: {
        Carousel,
        Slide,
        Pagination,
        Navigation
    },
    data() {
        return {
            images: [
                { id: 1, filename: imgMain1 },
                { id: 2, filename: imgMain2 },
            ]
        };
    },
    mounted() {
        setTimeout(() => {
            let eles = document.getElementsByClassName('VueCarousel-inner');
            for (let i = 0; i < eles.length; i++) {
                const ele = eles[i];
                ele.style.height = 'auto';
            }
        }, 100);
    }
};
</script>

<style>
.VueCarousel {
    overflow: hidden;
}
.main-content {
    position: absolute;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-flow: column;
}
.main-section .main-container .contact-btn {
    justify-content: center !important;
    padding-left: 0px;
}

.main-section .main-container {
    min-height: auto !important;
}
.slider {
    height: auto !important;
}
.slider-content img {
    width: 100% !important;
}
.slider-content,
.slider-image {
    display: flex;
}
.header1 {
    font-size: 24px;
}
@media (max-width: 992px) {
    .slider {
        min-height: 200px;
    }
    .main-content {
        position: relative !important;
    }
    .wrapper .main-section .main-container .contact-btn {
        bottom: 50px !important;
    }
}

.slider {
    height: 1500px;
    width: 100%;
    background-color: white;
}
.slider-content,
.slider-image {
    width: 100%;
    height: 100%;
    top: 0px;
    font-size: 25px;
}

.slider-image img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.slider-content-contain {
    background-size: contain !important;
    background-repeat: no-repeat !important;
    background-position: center !important;
}

.slider-title {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    color: white;
    font-weight: bolder;
    font-size: 50px;
    line-height: 38px;
}

.VueCarousel-dot-container {
    margin-top: -80px !important;
    z-index: 100;
    position: absolute;
    align-items: center;
    width: 100%;
    left: 0px;
}
.VueCarousel-dot {
    width: 15px !important;
    height: 15px !important;
    padding: 0px !important;
    margin-left: 7px;
    margin-right: 7px;
    box-shadow: 0px 2px 10px rgba(0, 0, 0, 1);
    outline: 1px solid white !important;
}
.VueCarousel-dot--active {
    padding: 0px !important;
    background-color: #ad7d3b !important;
}
.VueCarousel-navigation-button {
    cursor: default;
    z-index: 10;
    border-radius: 50%;
    width: 50px;
    height: 50px;
    color: #fff !important;
    background-color: rgba(134, 100, 76, 0.8) !important;
    text-align: center;
    box-shadow: 1px 2px 20px rgba(0, 0, 0, 1) !important;
    border: solid 1.5px white !important;
    font-size: 1.5rem !important;
    display: flex;
    justify-content: center;
    align-items: center;
}
.VueCarousel-navigation-prev {
    left: 100px !important;
}
.VueCarousel-navigation-next {
    right: 100px !important;
}
.VueCarousel-navigation--disabled {
    opacity: 0.5;
}

@media (max-width: 992px) {
    .slider-title {
        font-size: 25px;
        max-width: 70%;
        margin: auto;
        box-sizing: border-box;
        text-align: center;
    }
    .slider {
        height: 700px;
    }
}

@media (max-width: 768px) {
    .slider-title {
        bottom: 50px;
        align-content: flex-end;
        align-items: end;
        padding-bottom: 100px;
    }

    .VueCarousel-navigation-prev {
        left: 50px !important;
        width: 30px;
        height: 30px;
        font-size: 20px !important;
        line-height: 0px;
        text-align: center;
        padding: 0px !important;
    }

    .VueCarousel-navigation-next {
        right: 50px !important;
        width: 30px;
        height: 30px;
        font-size: 20px !important;
        line-height: 0px;
        text-align: center;
        padding: 0px !important;
    }
}
</style>
