<template>
    <div class="extends-4001gpar fayat-3943">
        <section class="header-section">
            <div class="header-logo">
                <div class="container">
                    <div class="row">
                        <div class="col-lg-6 col-md-12 text-lg-start text-center mb-3 mb-lg-0">
                            <img src="../assets/images/logo.png" class="hlogo" />
                        </div>
                        <div class="col-lg-6 col-md-12 text-lg-end text-center">
                            <img src="../assets/images/logo-right.png" class="hlogo-right" />
                        </div>
                    </div>
                </div>
            </div>
        </section>

        <SliderSection />

        <!--section class="price-section">
            <div class="container">
                <div class="row h-100">
                    <div class="d-flex justify-content-end align-items-center col-lg-6 col-md-12 text-lg-end text-center mb-3 mb-lg-0 pr-lg-5">
                        <div>
                            <div class="price-left-l1">{{ $t('Vous payez déjà un loyer ?') }}</div>
                            <div class="price-left-l2">{{ $t('Pourquoi ne pas') }}</div>
                            <div class="price-left-l3" v-scroll-to="'#form-container'" href="#form-container">{{ $t('devenir propriétaire ?') }}</div>
                        </div>
                    </div>
                    <div class="d-flex justify-content-start align-items-center col-lg-6 col-md-12 text-lg-start text-center pl-lg-5">
                        <div class="price-right">
                            <div class="price-right-l1">{{ $t('Votre appartement neuf') }}</div>
                            <div class="price-right-l2">{{ $t('à partir de') }}</div>
                            <div class="price-right-l3">{{ $t('1 040 €/mois*') }}</div>
                        </div>
                    </div>
                </div>
            </div>
        </section-->

        <section class="discover-section">
            <div class="container">
                <div class="row h-100">
                    <div class="d-flex justify-content-end align-items-center col-lg-6 text-lg-end text-center mb-3 mb-lg-0 pr-lg-5">
                        <div class="discover-left">
                            <img src="../assets/images/discover-left.png" />
                        </div>
                    </div>
                    <div class="d-flex justify-content-start align-items-center col-lg-6 text-lg-start text-center pl-lg-5">
                        <div class="discover-right">
                            <div class="discover-right-l1">
                                {{ $t('Découvrez votre') }}
                                {{ $t('nouvelle résidence intimiste') }}
                            </div>
                            <div class="discover-right-l2">
                                {{ $t('au calme et proche de tout') }}
                            </div>
                            <div class="discover-right-l3">
                                <div>
                                - <b>Seulement 28 appartements</b> du <b>studio</b> au <b>4 pièces</b>
                                </div>

                                <div>
                                - <b>Balcons, terrasses</b> ou <b>jardins privatifs</b> avec vue
                                sur le jardin intérieur pour la plupart
                                </div>

                                <div>
                                - <b>Parkings</b> en sous-sol
                                </div>

                                <div>
                                - <b>Accès rapide à Paris</b> et aux grands axes grâce
                                aux <b>autoroutes A3</b> et <b>A86</b>
                                </div>

                                <div>
                                - À quelques pas des <b>établissements scolaires privés</b>
                                et publics, de la <b>rue commerçante Outrebon</b>
                                avec son marché, ses <b>commerces</b> et tous les <b>services</b>
                                essentiels pour <b>un cadre de vie privilégié</b>
                                </div>

                                <div>
                                - <b>Gare RER E</b> et <b>nombreux bus</b> à proximité immédiate
                                pour un quotidien facilité
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>

        <section class="form-section" id="form-container">
            <div class="row h-100">
                <ContactForm class="d-flex justify-content-end align-items-center" />
            </div>
        </section>

        <section class="copyright">
            <div class="container h-100">
                <div class="row h-100">
                    <a href="https://www.grandparispromotion.fr/" target="_blank" class="d-flex justify-content-center align-items-center">
                        <img src="../assets/images/footer-logo.png" />
                    </a>
                </div>
            </div>
        </section>
    </div>
</template>

<script>
import { Hooper, Slide } from 'hooper';
import 'hooper/dist/hooper.css';

import ContactForm from '../components/ContactForm/ContactForm.vue';
import SliderSection from './Slider.vue';

export default {
    name: 'BigLandingView',
    components: {
        SliderSection,
        ContactForm,
        Hooper,
        Slide
    },
    computed: {
        slider() {
            return this.$refs.slider;
        },
        hooperSettings() {
            return {
                centerMode: true,
                itemsToShow: 1.2,
                infiniteScroll: true,
                transition: 500
            };
        }
    },
    methods: {
        slidePrev(refName) {
            if (this.$refs[refName]) {
                this.$refs[refName].slidePrev();
            }
        },
        slideNext(refName) {
            if (this.$refs[refName]) {
                this.$refs[refName].slideNext();
            }
        }
    }
};
</script>

<style lang="scss" scoped>
@import '../assets/styles/_index.scss';
.hlogo{
    @media (max-width: 900px) {
      max-width: 200px;
      }
    }

</style>
