<template>
    <div class="container contact-form">
        <div class="row input-columns">
            <div class="form-title">
                {{ $t('Pour en savoir plus ou être recontacté(e), remplissez le formulaire ci-dessous') }}
            </div>

            <form class="row g-3" @submit.prevent="sendForm">
                <div class="form-input-wrap row">
                    <div class="col-md-6 form-part pe-lg-3">
                        <input
                            v-model.trim="contactModel.name"
                            type="text"
                            class="form-control"
                            :placeholder="$t('NOM*')"
                            required
                            @keypress.once="startFillForm"
                        />
                        <input
                            v-model.trim="contactModel.surname"
                            type="text"
                            class="form-control"
                            :placeholder="$t('PRENOM*')"
                            required
                        />
                        <input
                            v-model="contactModel.mail"
                            type="email"
                            class="form-control"
                            :placeholder="$t('EMAIL*')"
                            required
                        />
                    </div>
                    <div class="col-md-6 form-part ps-lg-3">
                        <input
                            v-model="contactModel.phone"
                            type="tel"
                            class="form-control"
                            :placeholder="$t('TELEPHONE*')"
                            required
                        />
                        <input
                            v-model="contactModel.postalCode"
                            type="text"
                            class="form-control codepostal"
                            :placeholder="$t('CODE POSTAL*')"
                            required
                        />
                        <div v-click-outside="() => (isSelectOpen = false)" class="form-select">
                            <div
                                :class="['select-selected', { 'select-arrow-active': isSelectOpen }]"
                                @click="isSelectOpen = !isSelectOpen"
                            >
                                {{ contactModel.lotRooms.label }}
                            </div>
                            <ul :class="['select-items', { 'select-hide': !isSelectOpen }]">
                                <li
                                    v-for="option in piecesOptions"
                                    :key="option.label"
                                    :class="{ 'same-as-selected': contactModel.lotRooms.value === option.value }"
                                    @click="
                                        () => {
                                            contactModel.lotRooms = option;
                                            isSelectOpen = false;
                                        }
                                    "
                                >
                                    {{ option.label }}
                                </li>
                            </ul>
                        </div>
                    </div>

                    <div class="col-md-6 form-part pe-lg-3" id="radio-row">
                        <div class="row" style="margin-left: 0px">
                            <div class="col form-check">
                                <input
                                    id="habiter"
                                    value="HABITER"
                                    name="invest-type"
                                    type="radio"
                                    required="required"
                                    class="form-check-input"
                                    v-model="contactModel.investType"
                                />
                                <label class="form-check-label" for="habiter">{{ $t('HABITER') }}</label>
                            </div>
                            <div class="col form-check">
                                <input
                                    id="investir"
                                    value="INVESTIR"
                                    name="invest-type"
                                    type="radio"
                                    required="required"
                                    class="form-check-input"
                                    v-model="contactModel.investType"
                                />
                                <label class="form-check-label" for="investir">{{ $t('INVESTIR') }}</label>
                            </div>
                        </div>
                    </div>

                    <div class="col-md-6 form-part ps-lg-3">
                        <div class="captcha-row">
                            <img class="c-img" :src="captcha" alt="captcha" />
                            <input
                                v-model="contactModel.captcha"
                                class="form-input captcha-input"
                                type="text"
                                :placeholder="$t('Retapez ce code')"
                                required
                            />
                            <button type="button" class="captcha__refresh" @click="getCaptcha" />
                        </div>
                    </div>

                    <div class="col-md-12">
                        <div class="form-check">
                            <input
                                id="agreement"
                                v-model="agreement"
                                class="form-check-input"
                                type="checkbox"
                                required
                            />
                            <label class="form-check-label" for="agreement">
                                {{ $t('J’accepte d’être contacté(e) par un conseiller ERISMA*') }}
                                <br />
                                {{
                                    $t(
                                        '*Champs obligatoires. Les informations collectées sont destinées à l’usage exclusif de ERISMA. Pour connaître et exercer vos droits, notamment de retrait de votre consentement à l’utilisation des données par ce formulaire, veuillez consulter nos'
                                    )
                                }}
                                <router-link to="/mentions-legales" style="color: white">
                                    {{ $t('mentions légales.') }}
                                </router-link>
                            </label>
                        </div>
                    </div>
                </div>
                <div class="col-12">
                    <button class="btn btn-primary" type="submit">{{ $t('JE SOUHAITE ÊTRE RECONTACTÉ(E)') }}</button>
                </div>
            </form>
        </div>
    </div>
</template>

<script>
import axios from 'axios';
import clone from 'lodash.clonedeep';
import { mapActions, mapMutations, mapGetters } from 'vuex';

const PIECES = [
    { value: 1, label: 'STUDIO' },
    { value: 2, label: '2 PIÈCES' },
    { value: 3, label: '3 PIÈCES' },
    { value: 4, label: '4 PIÈCES' },
    { value: 5, label: '5 PIÈCES' },
];

const initialModel = {
    name: '',
    surname: '',
    mail: '',
    postalCode: '',
    phone: '',
    message: '',
    investType: 'HABITER',
    captcha: null,
    lotRooms: PIECES[0]
};

export default {
    name: 'ContactForm',
    data() {
        return {
            contactModel: clone(initialModel),
            agreement: false,
            isSelectOpen: false,
            isFormFilling: false,
            isSendingInProgress: false
        };
    },
    computed: {
        ...mapGetters({
            captcha: 'captcha/captcha',
            analyticsEvents: 'config/analyticsEvents'
        }),
        piecesOptions() {
            return PIECES
        }
    },
    mounted() {
        this.getCaptcha();
    },
    methods: {
        ...mapActions({
            getCaptcha: 'captcha/getCaptcha'
        }),
        ...mapMutations({
            updateContactFormModalProps: 'projectExtend/updateContactFormModalProps'
        }),
        async sendForm() {
            if (!this.agreement) {
                return;
            }

            if (this.isSendingInProgress) {
                return;
            }

            try {
                this.isSendingInProgress = true;
                const payload = clone(this.contactModel);
                payload.lotRooms = payload.lotRooms.value;
                await axios.post('/api/callback/landing-form', payload);
                // Ajout du pixel Xandr après soumission du formulaire
                pixie('event', 'Lead', {
                  item_name: 'demande de renseignement',
                  item_id: this.contactModel.mail // l'email
                });

                window.criteo_q = window.criteo_q || [];
                const deviceType = /iPad/.test(navigator.userAgent)
                    ? 't'
                    : /Mobile|iP(hone|od)|Android|BlackBerry|IEMobile|Silk/.test(navigator.userAgent)
                      ? 'm'
                      : 'd';
                window.criteo_q.push(
                    { event: 'setAccount', account: 100226 },
                    { event: 'setEmail', email: `${this.contactModel.mail}`, hash_method: 'sha256' },
                    { event: 'setSiteType', type: deviceType },
                    { event: 'setZipcode', zipcode: `${this.contactModel.postalCode}` },
                    { event: 'Homepage' }
                );
                this.updateContactFormModalProps({ modal: 'success', prop: 'isActive', value: true });
                this.contactModel = clone(initialModel);
                this.agreement = false;
                gtag('event', 'submit', { event_category: 'formulaire_contact', event_label: 'formulaire_contact' });
                gtag('event', 'conversion', { send_to: this.analyticsEvents?.googleContactFormId });
                fbq('track', 'Lead');
                typeof window.lintrk === 'function' && window.lintrk('track', { conversion_id: 12000026 });
            } catch (error) {
                console.error(error);
                this.contactModel.captcha = null;
                if (error.response?.data?.detail.includes('Le code saisi est incorrect')) {
                    this.$notify({
                        type: 'error',
                        title: 'Envoi non possible',
                        text: error.response?.data?.detail ?? ''
                    });
                } else {
                    this.updateContactFormModalProps({ modal: 'error', prop: 'isActive', value: true });
                }
            } finally {
                this.getCaptcha();
                this.isSendingInProgress = false;
            }
        },
        startFillForm() {
            if (this.isFormFilling) {
                return;
            }

            gtag('event', 'submit', {
                event_category: 'start_fill_form',
                event_label: 'start_fill_form'
            });
            gtag('event', 'conversion', { send_to: this.analyticsEvents?.googleStartFillFormId });

            this.isFormFilling = true;
        }
    }
};
</script>

<style lang="scss" scoped>
@import 'captcha.scss';
.btn-primary:hover {
  background-color: #86644B;
}
.contact-form .input-columns .select-items{
  height: 145px;
}
</style>
